import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../config/blueButtonOAuthConfig"; // Import the function for fetching the token
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { dbInstance } from "../../api/axios";
import { useAuth } from "../../context/auth-context";
import CustomLoader from "../custom-loader/CustomLoader";
import Modal from "react-bootstrap/Modal";

const BlueButtonCallback = () => {
  const navigate = useNavigate();
  const { setBlueButtonToken } = useAuth();
  const [show, setShow] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleClose = () =>{
    navigate("/");

  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");
    const errorDescription = urlParams.get("error_description");

    if (code) {
      // Fetch the token using the authorization code
      fetchToken(code)
        .then(async (userData) => {
          // Set the BlueButton access token in the context
          if (userData && userData.access_token) {
            setBlueButtonToken(userData.access_token);
          }

          navigate("/claim", { state: { fromBlueButton: true } });
        })
        .catch((fetchError) => {
          console.error("Error when fetching the token:", fetchError);

          // Here, you can handle failures after an unsuccessful token fetch. This might involve
          // redirecting the user to an error page, showing an error message, etc.
          // For instance:
          // navigate("/error");
        });
    } else if (error) {
      console.error(`OAuth Error: ${error}. Description: ${errorDescription}`);
     setShow(true)
    } else {
      console.error("Error: Authorization code not found!");
      // navigate("/login");
    }
  }, [navigate]);

  return( 
 <>
 <CustomLoader />
 <Modal
        show={show}
        onHide={handleClose}
        centered
        className='custom-pop-up about-me-popup'
        size='lg'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Request denied by the user,Plesae close the popup to move to home page.
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-border-primary me-2'
            onClick={() => {
              setIsCheckboxChecked(false);
              handleClose();
            }}
          >
           OK
          </button>
         
        </Modal.Footer>
      </Modal>
 </>

  );
};

export default BlueButtonCallback;
