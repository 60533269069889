import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getEnvVariable } from "../environments/env";

export class Http {
  private static axios = axios.create({
    baseURL: getEnvVariable().base_url,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static async get(url: string,params?: any, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken')
    const headers = {
        authorization: token ? `Bearer ${token}` : '',
        ClientId: process.env.REACT_APP_PAYER_ID, 
        ...(config?.headers || {})
      };
      const updatedConfig: AxiosRequestConfig = { ...config, headers,params };
      const response = await Http.axios.get(url, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static async post(url: string, data: any, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken');
      const headers = {
        authorization: token ? `Bearer ${token}` : '',
        ClientId: process.env.REACT_APP_PAYER_ID, // Add ClientId to headers
        ...(config?.headers || {}), // Merge additional headers if any
      };
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response: AxiosResponse = await Http.axios.post(url, data, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }
  static async put(url: string, data: any, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken');
      const headers = {
        authorization: token ? `Bearer ${token}` : '',
        ClientId: process.env.REACT_APP_PAYER_ID, // Add ClientId to headers
        ...(config?.headers || {}), // Merge additional headers if any
      };
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response: AxiosResponse = await Http.axios.put(url, data, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }
  private static handleErrors(error: any) {
    if (error) {
      const errorMessage =
        error||
        "Something Went Wrong. Please Try Again";
       alert(errorMessage)
    } else {
     alert("Something went wrong. Please try again.");
    }
  }
}