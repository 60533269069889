export const urls = {
    previousInsurance: {
      getPayerbyId: (id: number) => `/PortalPayer/payer/${id}`,
      getPayerConfiguration: (payerID: number) => `/PortalPayer/payers-with-config/${payerID}`,
      getPayersForDropDown: `/PortalPayer/payers/GetPayersForDropdown`,
      updatePayer:`/PortalPayerMember/member`,
      updateDatabse: `/PortalPayerMember/member`,
      getListData: (
        payerName:string,
        SSOUserID: string,
        page: number,
        pageSize: number,
        sortColumn: string = 'ID',
        sortOrder: string = 'desc'
      ) => 
        `/PortalPayerMember/search?payerName=${payerName}&SSOUserID=${SSOUserID}&page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
         getRefreshToken:"PortalPayerMember/search",
         getFhirUrl:(payerId:number)=>`/PortalPayerFhirRes/search?resourceName=ExplanationOfBenefit&payerID=${payerId}&page=1&pageSize=10&sortColumn=ResourceID&sortOrder=desc`
    },
  
  };
  