import axios from "axios";

const ssoInstance = axios.create({
  baseURL: process.env.REACT_APP_SSO_INSTANCE,
});

const dbInstance = axios.create({
  baseURL: process.env.REACT_APP_SSO_dbInstance,
});

const hl7Instance = axios.create({
  baseURL: process.env.REACT_APP_SSO_hl7Instance,
});

const blueButtonData = axios.create({
  baseURL: process.env.REACT_APP_BLUEBUTTON_BASEURL,
});

const FHIRData = (baseURL)=>{
  return axios.create({baseURL})
}
export { ssoInstance, dbInstance, hl7Instance, blueButtonData,FHIRData };