import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  hl7Instance,
  blueButtonData,
  dbInstance,
  FHIRData,
} from "../../api/axios";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./claims.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import { urls } from "../../api/Urls";
import { Http } from "../../api/Http";
import {
  toTitleCase,
  formatCurrency,
  formatDateToDDMMYY,
} from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import {
  getFhirURI,
  getPayerConfiguration,
  getPayerInformationbyID,
  getRefreshToken,
} from "../../redux/slice/PrevInsuranceSlice";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

async function getDynamicPatientInfo() {
  let accessToken;
  let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
  let userSub = "";
  const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");
  if (ImpersonateUserID) {
    userSub = ImpersonateUserID;
    accessToken = PortalToken;
  } else {
    accessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
    userSub = tokenPayload.sub;
  }

  const patientInfoResponse = await dbInstance.get(`/member/${userSub}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {
    patientID: patientInfoResponse.data.MemberID,
    isActive: patientInfoResponse.data.BB_IsActive,
  };
}
const fetchPatientData = async () => {
  try {
    let accessToken;
    let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
    let userSub = "";
    const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");

    // Use ImpersonateUserID if available, else get the access token
    if (ImpersonateUserID) {
      userSub = ImpersonateUserID;
      accessToken = PortalToken;
    } else {
      // Fetch access token from the getAccessToken function
      accessToken = await getAccessToken();

      const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));

      // Assign 'sub' claim from token payload as userSub
      userSub = tokenPayload.sub;
    }

    // Call the API with userSub and the Bearer token
    const patientIdResponse = await dbInstance.get(`/member/${userSub}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const memberData = patientIdResponse.data;
    return {
      MemberID: memberData.MemberID,
      BB_MemberID: memberData.BB_MemberID,
    };
  } catch (error) {
    console.error("Error in fetching patient data: ", error);
    throw error;
  }
};

const Claim = () => {
  const location = useLocation();

  const payerData = useSelector((state) => state?.insuranceData);
  const dispatch = useDispatch();
  const [code,setCode]=useState(null)
  const [payerClaims, setPayerClaims] = useState([]);
  const { blueButtonToken, setBlueButtonToken } = useAuth();
  const [claims, setClaims] = useState([]);
  const [fhirUrl, setFhirUrl] = useState("");
  const [nextFhirUrl, setNextFhirUrl] = useState("");
  const [payerListData, SetPayerListData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [searchParams, setSearchParams] = useState({
    patientId: localStorage.getItem("patientId") || "",
    fromDate: "",
    toDate: "",
  });
  const [data, setData] = useState({
    tokenURL: "",
    client_id: "",
    clientSecret: "",
    redirect_uri: "",
    payerMemberID: "",
    refreshToken: "",
  });
  const [isFromBlueButton, setIsFromBlueButton] = useState(
    location.state?.fromBlueButton || location.pathname.includes("bluebutton")
  );
  const [isFromDetail] = useState(location.state?.fromDetail);
  const defaultSource = isFromBlueButton ? "CMS" : "Healthplan";
  const [source, setSource] = useState(defaultSource);
  const [isActiveCMS, setIsActiveCMS] = useState(isFromBlueButton);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [page] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalClaims, setTotalClaims] = useState(0);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrls, setPrevUrls] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [userSsoId, setUserSsoId] = useState("");
  const [selectedPayerID, setSelectedPayerID] = useState(null);
  const [selectedPayerName, setSelectedPayerName] = useState("");
  const [payerID, setPayerID] = useState(null);
  const [isPayerDetailsFetched, setIsPayerDetailsFetched] = useState(false);
  const [paginationUrls, setPaginationUrls] = useState({
    current: "",
    next: "",
    prev: "",
  });
  const fhirInstance = FHIRData(nextFhirUrl);
  const initialUrl = "";
  const [urlStack, setUrlStack] = useState([initialUrl]);
  const [recordsPerPage] = useState(10);
  const currentClaims = payerClaims.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const prevPageRef = useRef();
  const [tempDates, setTempDates] = useState({
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    if (payerData.payerConfiguration) {
      const clientID =
        payerData.payerConfiguration.find(
          (item) => item.configKey === "client_id"
        )?.configValue || "";
      const clientSecret =
        payerData.payerConfiguration.find(
          (item) => item.configKey === "clientSecret"
        )?.configValue || "";
      const redirectURI =
        payerData.payerConfiguration.find(
          (item) => item.configKey === "redirect_uri"
        )?.configValue || "";

      setData((prevData) => ({
        ...prevData,
        client_id: clientID,
        clientSecret: clientSecret,
        redirect_uri: redirectURI,
        tokenURL: payerData?.payerInformation?.tokenURL,
      }));
    }
  }, [payerData]);
  const handleFromDateChange = (date) => {
    setTempDates((prevDates) => ({
      ...prevDates,
      fromDate: date,
    }));
  };

  const handleToDateChange = (date) => {
    setTempDates((prevDates) => ({
      ...prevDates,
      toDate: date,
    }));
  };

  const updateDatabase = async () => {
    const refresh_token = localStorage.getItem("B_RefreshToken");
    const patient = localStorage.getItem("B_patientId");
    const ssoAccessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(ssoAccessToken.split(".")[1]));
    const userSub = tokenPayload.sub;
    if (!refresh_token || !patient) {
      return;
    }

    const updatePayload = {
      SSOGuid: userSub,
      BB_IsActive: "Yes",
      BB_MemberID: patient,
      BB_RefreshToken: refresh_token,
    };
    dbInstance.put("/member", updatePayload, {
      headers: { Authorization: `Bearer ${ssoAccessToken}` },
    });
  };

  const handleSearch = () => {
    // Ensure that fromDate and toDate are valid Date objects or handle them as needed
    const formattedFromDate = tempDates.fromDate
      ? formatDateForQuery(tempDates.fromDate)
      : "";
    const formattedToDate = tempDates.toDate
      ? formatDateForQuery(tempDates.toDate)
      : "";

    setSearchParams({
      ...searchParams,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });

    if(source==="Healthplan" || source==="CMS"){
      fetchClaims({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      });
    }
  
  };

  const fetchPayerList = async (payerName, id, page, pageSize, sortColumn) => {
    try {
      const response = await Http.get(
        urls.previousInsurance.getListData(
          payerName,
          id,
          page,
          pageSize,
          sortColumn
        )
      );
      if (response) {
        const payerNameList = response?.items?.map((item) => ({
          id: item.id,
          payerName: item?.payerName,
          payerID: item.payerID,
          memberID: item.payerMemberID,
          isActive: item.isActive,
          ssoID: item.ssoUserID,
        }));
        SetPayerListData(payerNameList); // Set only the payerNameList
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
    } finally {
      setLoading(false); // Set loading to false after the API call completes
    }
  };
  useEffect(() => {
    if (isFromBlueButton) {
      setSource("CMS");
      updateDatabase();
      setIsActiveCMS(true);
      setIsFromBlueButton(false);
    } else {
      getDynamicPatientInfo().then((dynamicPatientInfo) => {
        setSearchParams((prevState) => ({
          ...prevState,
          patientId: dynamicPatientInfo.patientID,
        }));
        setIsActiveCMS(dynamicPatientInfo.isActive.trim() === "Yes");
      });
    }
  }, [isFromBlueButton]);
  useEffect(() => {
    const handlePayerDetails = async () => {
      try {
        setLoading(true);
        const response = await dispatch(
          getPayerInformationbyID(selectedPayerID)
        );
        if (response) {
          setData((prevState) => ({
            ...prevState,
            tokenURL: response?.payload?.tokenURL || "",
          }));
        }
       const configurationDetails = await dispatch(getPayerConfiguration(selectedPayerID));
       console.log("configuration details are======>",configurationDetails)
        const refreshResponse = await dispatch(
          getRefreshToken(selectedPayerID)
        );
        console.log("refresh toake is====>",refreshResponse)
        if (refreshResponse) {
          const items = refreshResponse.payload?.items;
          const matchedPayer = items?.find(
            (item) => item.payerID === selectedPayerID
          );
          if (matchedPayer && matchedPayer.refreshToken) {
            console.log("matched payer is=====>",matchedPayer)
            localStorage.setItem(
              "Payer_refreshToken",
              matchedPayer.refreshToken
            );
            setCode(matchedPayer.refreshToken)
            localStorage.setItem("Payer_ID", matchedPayer.payerMemberID);
          }else {
            console.warn("Matched payer is not available or refreshToken is undefined");
          }
        }
        // Mark that payer details have been fetched
        setIsPayerDetailsFetched(true);
      } catch (error) {
        console.error("Error fetching payer details:", error);
      }
    };

    if (source !== "CMS" && source !== "Healthplan") {
      handlePayerDetails();
    }
  }, [source, selectedPayerID]); // Add selectedPayerID as a dependency



  // Second useEffect
  useEffect(() => {
    if (isPayerDetailsFetched && source !== "CMS" && source !== "Healthplan") {
      fetchToken();
      // Reset the fetched state if you want to refetch on source change
      setIsPayerDetailsFetched(false);
    }
  }, [isPayerDetailsFetched, source, data,]);

  const formatDateForQuery = (date) => {
    // Check if the date is an instance of Date and is not null
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const fetchClaims = async ({ fromDate, toDate }) => {
    setLoading(true);
    if (source === "CMS") {
      const storedToken =
        localStorage.getItem("BB_NewAccess_Token") ||
        sessionStorage.getItem("BB_NewAccess_Token") ||
        localStorage.getItem("local_B_AccessToken") ||
        sessionStorage.getItem("Session_B_AccessToken");
      if (storedToken) {
        setBlueButtonToken(storedToken);
      }
    }
    let patientIdObject = await fetchPatientData();
    let currentPatientId = null;
    let queryString = new URLSearchParams();
    if (fromDate && toDate) {
      queryString.append("service-date", `ge${fromDate}`);
      queryString.append("service-date", `le${toDate}`);
    } else if (fromDate) {
      queryString.append("service-date", `ge${fromDate}`);
    } else if (toDate) {
      queryString.append("service-date", `le${toDate}`);
    }

    if (source === "CMS" && patientIdObject.BB_MemberID) {
      currentPatientId = patientIdObject.BB_MemberID && patientIdObject.BB_MemberID;
      if (!currentPatientId) {
        const CMSSessionPatientid = sessionStorage.getItem("B_patientId");
        currentPatientId = CMSSessionPatientid;
      }
    } else if (source === "Healthplan" && patientIdObject.MemberID) {
      currentPatientId = patientIdObject.MemberID;
    }

    if (!currentPatientId) {
      console.error(
        "No patient ID found for the given source:",
        source,
        patientIdObject
      );
    }
    queryString.append("patient", currentPatientId);
    queryString.append("_count", "10");
    queryString.append("status", "active");
    queryString.append("_sort", "-service-date");
    if (source === "CMS") {
      queryString.append("type", "outpatient,inpatient");
    }

    let headers = {};
    let requestInstance = blueButtonData;

    if (source === "Healthplan") {
      const accessToken = await getAccessToken();
      headers = { Authorization: `Bearer ${accessToken}` };
      requestInstance = hl7Instance;
    } else {
      const accessToken = await blueButtonToken;
      headers = { Authorization: `Bearer ${accessToken}` };
    }

    try {
      const endpoint = "/ExplanationOfBenefit/";
      const response = await requestInstance.get(endpoint, {
        headers: headers,
        params: queryString,
      });
      const data = response.data;
      setClaims(data.entry || []);
      setTotalClaims(data.total || 0);
      const computedPage = Math.floor(((data.entry?.length || 0) - 1) / 10) + 1;
      setCurrentPage(computedPage);
      const nextLink = data.link.find((l) => l.relation === "next");
      const baseURL = requestInstance.defaults.baseURL || "";
      const fullURL = `${baseURL}${endpoint}?${queryString}`;
      setUrlStack([fullURL]);
      if (nextLink) {
        setPaginationUrls({
          current: fullURL,
          next: nextLink.url,
          prev: "",
        });
        setPrevUrls(fullURL);
        setNextUrl(nextLink.url);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleClearSearch = () => {
    setTempDates({ fromDate: null, toDate: null });
    setSearchParams({ ...searchParams, fromDate: "", toDate: "" });
    fetchClaims({ fromDate: null, toDate: null });
  };
  useEffect(() => {
    if (source === "Healthplan" || source === "CMS")
      fetchClaims({
        fromDate: searchParams.fromDate,
        toDate: searchParams.toDate,
      });
  }, [source, blueButtonToken]);

  useEffect(() => {
    if (page < prevPageRef.current) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [claims]);

  useEffect(() => {
    prevPageRef.current = page;
  }, [page]);

  useEffect(() => {
    if (isFromDetail) {
      setSource(location.state?.source || defaultSource);
    }
  }, [isFromDetail, location.state?.source]);
  const fetchNextPage = async () => {
    try {
      setError(null);
      setLoading(true);
      const accessToken =
        source === "Healthplan"
          ? await getAccessToken()
          : source === "CMS"
          ? blueButtonToken
          : authToken;
      const response = await (source === "Healthplan"
        ? hl7Instance
        : source === "CMS"
        ? blueButtonData
        : fhirInstance
      ).get(paginationUrls.next, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      const prevLink = data.link.find((link) => link.relation === "previous");
      if (prevLink) {
        const prevUrl = prevLink.url;
        setNextFhirUrl(prevUrl); // Assuming setNextUrl is the state setter for the next URL
        setPaginationUrls((prev) => ({
          ...prev,
          prev: prevUrl,
        }));
      }
      if (source !== "CMS" && source !== "Healthplan") {
        setLoading(true);
        const claims =
          data.entry.map((item) => ({
            ...item,
            fullURL: item.fullUrl, // Assuming fullUrl is a property of item
          })) || [];

        setPayerClaims((prevClaims) => [...prevClaims, ...claims]);
        setLoading(false);
      }
      const nextLink = data.link.find((l) => l.relation === "next");
      setUrlStack((prevUrls) => [...prevUrls, paginationUrls.current]); // push the current URL
      setPaginationUrls({
        current: paginationUrls.next,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 1] || "", // Set the previous URL
      });
      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage + 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Next Page Error:", error);
      setError(
        "An error occurred while fetching the next page. Please try again."
      );
    }
  };
  const fetchPreviousPage = async () => {
    try {
      setError(null);
      setLoading(true);

      if (urlStack.length <= 1) return;
      const prevUrl = urlStack[urlStack.length - 1];
      const accessToken =
        source === "Healthplan"
          ? await getAccessToken()
          : source === "CMS"
          ? blueButtonToken
          : authToken;
      const response = await (source === "Healthplan"
        ? hl7Instance
        : source === "CMS"
        ? blueButtonData
        : fhirInstance
      ).get(prevUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      const nextLink = data.link.find((l) => l.relation === "next");

      setUrlStack((prevUrls) => {
        const newUrls = [...prevUrls];
        newUrls.pop();
        return newUrls;
      });

      setPaginationUrls({
        current: prevUrl,
        next: nextLink ? nextLink.url : "",
        prev: urlStack[urlStack.length - 2] || "",
      });
      setClaims(data.entry || []);
      setCurrentPage((prevPage) => prevPage - 1);
      if (totalClaims === null) {
        setTotalClaims(data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch Previous Page Error:", error);
      setError(
        "An error occurred while fetching the previous page. Please try again."
      );
    }
  };
  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(totalClaims / ITEMS_PER_PAGE);

  const RenderHealthPlanContent = () => (
    <>
      <PerfectScrollbar className="table-responsive custom-main-table mb-2">
        <table className="table">
          <thead className="sticky-top z-1">
            <tr>
              <th>Claim Number</th>
              <th>Provider Name</th>
              <th>Date of Service</th>
              <th>Billed Amount</th>
              <th>Claim Status</th>
              <th>Claim Type</th>
            </tr>
          </thead>
          <tbody>
            {claims.map((claim, index) => (
              <tr key={index + "_key"}>
                <td>
                  <Link to={`/claim/${claim.resource.id}/${source}`}>
                    {claim.resource.identifier?.find(
                      (id) =>
                        id.system ===
                        "https://terminology.hl7.org/resources/variables/clm_id"
                    )?.value || "N/A"}
                  </Link>
                </td>
                <td>
                  {toTitleCase(claim.resource.provider?.display || "N/A")}
                </td>
                <td>
                  {formatDateToDDMMYY(claim.resource.billablePeriod?.start)}
                </td>
                <td>
                  {formatCurrency(
                    claim.resource.extension?.find(
                      (ext) =>
                        ext.url ===
                        "https://terminology.hl7.org/resources/codesystem/eob-billed-amount"
                    )?.valueMoney?.value
                  ) || "N/A"}
                </td>
                <td>
                  {toTitleCase(
                    claim.resource.extension?.find(
                      (ext) =>
                        ext.url ===
                        "https://terminology.hl7.org/resources/codesystem/eob-status"
                    )?.valueCoding?.code || "N/A"
                  )}
                </td>
                <td>
                  {claim.resource.type?.text ||
                    (claim.resource.type?.coding &&
                    claim.resource.type.coding.length > 0
                      ? claim.resource.type.coding[0].code
                      : null) ||
                    "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );
  useEffect(() => {
    const token = localStorage.getItem("PortalAdmin-AccessToken");
    if (token) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const payload = JSON.parse(jsonPayload);
        setUserSsoId(payload.sub); // Extract the 'sub' value from the payload
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    if (userSsoId) {
      fetchPayerList("", userSsoId, 1, 50);
    }
  }, [userSsoId]);

  useEffect(() => {
    if (payerClaims.length === 0) {
      setTotalClaims(0);
    }
  }, [payerClaims]);
  const RenderCMSContent = () => (
    <>
      <PerfectScrollbar className="table-responsive custom-main-table mb-2">
        <table className="table">
          <thead className="sticky-top z-1">
            <tr>
              <th>Claim Number</th>
              <th>Provider Name</th>
              <th>Date of Service</th>
              <th>Billed Amount</th>
              <th>Claim Status</th>
              <th>Claim Type</th>
            </tr>
          </thead>
          <tbody>
            {claims.map((claim) => (
              <tr key={claim.resource.id}>
                <td>
                  <Link to={`/claim/${claim.resource.id}/${source}`}>
                    {claim.resource.identifier?.find(
                      (ident) =>
                        ident.system ===
                        "https://bluebutton.cms.gov/resources/variables/clm_id"
                    )?.value || "N/A"}
                  </Link>
                </td>
                <td>
                  {toTitleCase(claim.resource.contained?.[0]?.name || "N/A")}
                </td>
                <td>
                  {formatDateToDDMMYY(claim.resource.billablePeriod?.start) ||
                    "N/A"}
                </td>
                <td>
                  {" "}
                  {formatCurrency(claim.resource.payment?.amount?.value) ||
                    "N/A"}
                </td>
                <td>{toTitleCase(claim.resource.status || "N/A")}</td>
                <td>
                  {claim.resource.type?.coding?.find(
                    (coding) =>
                      coding.system ===
                      "https://bluebutton.cms.gov/resources/codesystem/eob-type"
                  )?.code || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );
  const RenderPayerClaims = () => (
    <>
      <PerfectScrollbar className="table-responsive custom-main-table mb-2">
        <table className="table">
          <thead className="sticky-top z-1">
            <tr>
              <th>Claim Number</th>
              <th>Provider Name</th>
              <th>Date of Service</th>
              <th>Billed Amount</th>
              <th>Claim Status</th>
              <th>Claim Type</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center my-4">
                  <div className="spinner-border" role="status"></div>
                </td>
              </tr>
            ) : payerClaims && payerClaims.length > 0 ? (
              currentClaims.map((claim) => (
                <tr key={claim.resource.id}>
                  <td>
                    <Link to={claim?.fullURL}>{claim.resource.id}</Link>
                  </td>
                  <td>
                    {claim.resource?.insurer?.display
                      ? claim.resource?.insurer?.display
                      : "-"}
                  </td>
                  <td>
                    {claim.resource.billablePeriod?.start
                      ? formatDateToDDMMYY(claim.resource.billablePeriod.start)
                      : "N/A"}
                  </td>
                  <td>
                    {formatCurrency(claim.resource.payment?.amount?.value) ||
                      "N/A"}
                  </td>
                  <td>{toTitleCase(claim.resource.status || "N/A")}</td>
                  <td>{claim?.resource.resourceType || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center my-4">
                  <p>No Data Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PerfectScrollbar>
    </>
  );
  const RenderPagination = () => {
    return (
      <>
        <span className="pagination-info me-2">Page</span>
        <ul className="pagination d-flex justify-content-end mb-0">
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={fetchPreviousPage}
                disabled={currentPage === 1}
              >
                <i className="fh_arrow_left_line"></i>
              </button>
            </li>
          )}
          <li className="page-item">
            <button className="page-link current-page">{currentPage}</button>
          </li>
          <li className="page-item">
            <button
              className="page-link me-0"
              onClick={fetchNextPage}
              disabled={currentPage >= totalPages}
            >
              <i className="fh_arrow_right_line"></i>
            </button>
          </li>
        </ul>  
      </>
    );
  };
  const fetchPayerClaim = async () => {
    if (!fhirUrl) return;
    setLoading(true);
    // const updatedUrl =
    //   fhirUrl.replace("{patient}", selectedPatient) + "&_count=10";
    const updatedUrl =
      fhirUrl.replace("{patient}", selectedPatient);
    try {
      const token = authToken; 
      const response = await axios.get(updatedUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (response) {
        const claims =
          response.data.entry.map((item) => ({
            ...item,
            fullURL: item.fullUrl, // Assuming fullUrl is a property of item
          })) || [];

        setPayerClaims((prevClaims) => [...prevClaims, ...claims]);
        setTotalClaims(response?.data.total); // Store the total count of claims
        const nextLink = response.data.link.find(
          (link) => link.relation === "next"
        );

        if (nextLink) {
          const nextUrl = nextLink.url;
          setNextFhirUrl(nextUrl); // Assuming setNextUrl is the state setter for the next URL
          setPaginationUrls((prev) => ({
            ...prev,
            current: nextUrl,
            next: nextUrl,
          }));
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after the API call completes
    }
  };

  const fetchFhirUrl = async () => {
    try {
      setLoading(true);
      let result =
        selectedPayerID && (await dispatch(getFhirURI(selectedPayerID)));
      setFhirUrl(result?.payload?.items[0].fhirUrl);
      await fetchPayerClaim();
      setLoading(false);
      return result;
    } catch (error) {
      console.error("issue in fetching fhir url", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fhirUrl) {
      fetchPayerClaim();
    }
  }, [fhirUrl, payerListData]);

  const createDataBaseEntry = async (payerMemberID, refreshToken) => {
    setLoading(true);
    let accessToken;
    let userSub = "";
    accessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
    userSub = tokenPayload.sub;
    let mdata = {
      id: payerID && payerID,
      ssoUserID: userSub,
      payerID: selectedPayerID && selectedPayerID,
      payerMemberID: payerMemberID, //data?.payerMemberID,
      refreshToken: refreshToken, //data?.refreshToken,
      tokenExpiry: new Date().toISOString(),
      isActive: true,
      activeDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
    };

    try {
      PreviousInsuranceApi.updatePayerInformation(mdata).then((response) => {
        if (response) {
          fetchFhirUrl();
        }
      });
    } catch (error) {
      console.error("Error while fetching memeber data==========>", error);
    } finally {
    }
  };

  const fetchToken = async () => {
    setLoading(true);
    const { tokenURL, client_id, clientSecret } = data;
    if (!tokenURL || !client_id || !clientSecret || !code) {
      console.error("Missing required values:", { tokenURL, client_id, clientSecret, code });
      setLoading(false);
      return; // Exit early if values are missing
    }
    const credentials = window.btoa(`${client_id}:${clientSecret}`);
    const postData = {
      refresh_token: code && code,
      grant_type: "refresh_token",
    };

    try {
      const response = await axios.post(
        tokenURL,
        new URLSearchParams(postData).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
            Authorization: `Basic ${credentials}`,
          },
        }
      );

      if (response) {
        setAuthToken(response?.data.access_token);
        const updatedPayerMemberID = response?.data.patient;
        const updatedRefreshToken = response?.data.refresh_token;
        if (typeof updatedRefreshToken === "string") {
          await createDataBaseEntry(updatedPayerMemberID, updatedRefreshToken);
        } else if (updatedRefreshToken === "" || undefined) {
        } else {
          fetchFhirUrl();
        }
      }
    } catch (fetchError) {
      console.error("Error fetching the token:", fetchError);
      setLoading(false);
      throw fetchError;
    } finally {
    }
  };

  const handleSourceChange = async (e) => {
    setLoading(true);
    const newSource = e?.target.value;
    setSource(newSource);
    const selectedPayer = payerListData.find(
      (payer) => payer.payerName === newSource
    );
    if (selectedPayer) {
      setSelectedPayerName(selectedPayer.payerName);
      setSelectedPayerID(selectedPayer.payerID);
      setPayerID(selectedPayer.id);
      setSelectedPatient(selectedPayer.memberID);
    } else {
      console.warn("Selected payer not found for source:", newSource);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!payerClaims.length) {
      setTotalClaims(0);
    }
  }, [payerClaims]);
  return (
    <>
      <>
        <div className="main-content">
          <div className="content-header">
            <h1 className="page-header">Claims</h1>
          </div>
          <div className="card">
            <div className="card-body">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="card-form form-content mt-0">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                    <label htmlFor="source" className="d-flex form-label">
                      Source Plan
                    </label>

                    <div className="form-field position-relative">
                      <select
                        className="form-select form-control h-48 cursor-pointer"
                        id="source"
                        value={source}
                        onChange={handleSourceChange}
                      >
                        <option value="Healthplan">Health Plan</option>
                        {isActiveCMS && <option value="CMS">CMS</option>}
                        {payerListData &&
                          payerListData
                            .filter((payer) => payer.isActive)
                            .map((payer, index) => (
                              <option key={index} value={payer?.payerName}>
                                {payer?.payerName}
                              </option>
                            ))}
                      </select>
                      <span className="field-icon position-absolute top-50 translate-middle-y">
                        <i className="fh_arrow_down_line"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                    <label htmlFor="fromDate" className="d-flex form-label">
                      From Date of Service
                    </label>
                    <div className="form-field">
                      <DatePicker
                        className={`form-control h-48`}
                        placeholderText="MM / DD / YYYY"
                        selected={tempDates.fromDate}
                        onChange={handleFromDateChange}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-4 mb-xl-0">
                    <label htmlFor="toDate" className="d-flex form-label">
                      To Date of Service
                    </label>
                    <div className="form-field position-relative">
                      <DatePicker
                        id="toDate"
                        className={`form-control h-48`}
                        placeholderText="MM / DD / YYYY"
                        selected={tempDates.toDate}
                        onChange={handleToDateChange}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-12 col-xl-3 d-flex align-items-end align-items-sm-center align-items-md-end justify-content-start justify-content-center justify-content-md-center justify-content-xl-start justify-content-sm-start col-12 col-md-auto col-xs-12">
                    <button
                      type="button"
                      className="btn btn-primary h-48 me-3"
                      onClick={handleSearch}
                    >
                      <span>Search</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-border-primary h-48"
                      onClick={handleClearSearch}
                    >
                      <span>Clear</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="content-subheader d-flex align-items-center">
                  <h2 className="page-subheader me-3">
                    {source}{" "}
                    Claim List
                  </h2>
                  <span className="total-record">
                    {/* [ Total Claims: {totalClaims ? totalClaims :!payerClaims ?0 :0} ] */}
                    [ Total Claims: {loading ? 0 : totalClaims} ]
                  </span>
                </div>
                {loading ? (
                  <>
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status"></div>
                    </div>
                  </>
                ) : (
                  <>
                    {source === "Healthplan" ? (
                      <RenderHealthPlanContent />
                    ) : source === "CMS" ? (
                      <RenderCMSContent />
                    ) : (
                      <RenderPayerClaims />
                    )}
                    <div className="d-flex justify-content-end">
                      <nav
                        aria-label="Page navigation"
                        className="d-flex align-items-center"
                      >
                        {((claims && source === "Healthplan") ||
                          source === "CMS") && <RenderPagination />}
                        {payerClaims.length > 0 && <RenderPagination />}
                      </nav>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Claim;
